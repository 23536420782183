
import { Vue, Component } from 'vue-property-decorator'
import { DeviceNetworkInfo } from '@/types/devices'
import NetworkDetail from './NetworkDetail.vue'
import NetworkAdd from './NetworkAdd.vue'

@Component({
  components: { NetworkDetail, NetworkAdd }
})
export default class Network extends Vue {
  deviceId = ''
  loading = false
  tableData: DeviceNetworkInfo[] = []
  page = 1
  size = 10
  total = 0
  detailDialog = false
  detailId =''
  addDialog = false

  created () {
    this.deviceId = this.$route.params.deviceId
    this.getList()
  }

  getList () {
    this.loading = true
    this.$axios
      .get<{ total: number; list: DeviceNetworkInfo[] }>(
        this.$apis.devices.selectDeviceNetworkByList,
        {
          page: this.page,
          size: this.size,
          deviceId: this.deviceId
        }
      )
      .then((res) => {
        this.total = res.total || 0
        this.tableData = res.list || []
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 详情
  onDetail (id: string) {
    this.detailDialog = true
    this.detailId = id
  }

  closeDetailDialog () {
    this.detailDialog = false
    this.detailId = ''
  }

  // 新增
  addNetwork () {
    this.addDialog = true
  }

  // 编辑
  onEdit (id: string) {
    this.addDialog = true
    this.detailId = id
  }

  // 新增成功
  saveSuccess () {
    this.closeAddDialog()
    this.page = 1
    this.getList()
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.devices.deleteDeviceNetwork, {
          id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getList()
          }).finally(() => {
            this.loading = false
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  closeAddDialog () {
    this.addDialog = false
    this.detailId = ''
  }
}
