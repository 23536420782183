
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DeviceNetworkInfo } from '@/types/devices'

@Component
export default class NetworkDetail extends Vue {
  @Prop({ default: false }) private detailDialog!: boolean
  @Prop({ default: '' }) private id!: string

  detailInfo: DeviceNetworkInfo | null = null
  loading=false

  created () {
    this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios
      .get(this.$apis.devices.selectDeviceNetworkById, {
        id: this.id
      })
      .then((res) => {
        this.detailInfo = res
      }).finally(() => {
        this.loading = false
      })
  }

  handleClose () {
    this.$emit('closeDialog')
  }
}
