
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DeviceNetworkInfo, SubmitForm } from '@/types/devices'
import { ElForm } from 'element-ui/types/form'
import { cardNumberValidator } from '@/utils/validate'

@Component
export default class NetworkDetail extends Vue {
  @Prop() readonly deviceId!: string;
  @Prop({ default: false }) private addDialog!: boolean
  @Prop({ default: '' }) private id?: string

  detailInfo: DeviceNetworkInfo | null = null
  submitDisabled = false
  loading = false
  formData: SubmitForm = {
    cardType: '',
    cardNumber: '',
    administration: '',
    operators: '',
    dateStart: '',
    dateEnd: '',
    remarks: '',
    dateArr: []
  }

  cardTypeList: Array<{ label: string; value: string }> = [
    {
      label: 'NB物联网',
      value: '1'
    },
    {
      label: '4G',
      value: '2'
    },
    {
      label: '2G',
      value: '3'
    }
  ]

  administrationList: Array<{ label: string; value: string }> = [
    {
      label: '自购',
      value: '1'
    },
    {
      label: '设备厂商',
      value: '2'
    }
  ]

  operatorsList: Array<{ label: string; value: string }> = [
    {
      label: '中国移动',
      value: '1'
    },
    {
      label: '中国电信',
      value: '2'
    },
    {
      label: '中国联通',
      value: '3'
    }
  ]

  rules = {
    cardType: [{ required: true, message: '请选择类型', trigger: ['blur', 'change'] }],
    cardNumber: [{ required: true, validator: cardNumberValidator, trigger: ['blur', 'change'] }],
    administration: [
      { required: true, message: '请选择管理方', trigger: ['blur', 'change'] }
    ],
    operators: [{ required: true, message: '请选择运营商', trigger: ['blur', 'change'] }],
    dateArr: [
      { required: true, message: '请选择有效期', trigger: ['blur', 'change'] }
    ]
  }

  get title () {
    return this.id ? '编辑' : '新增'
  }

  created () {
    this.id && this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios
      .get(this.$apis.devices.selectDeviceNetworkById, {
        id: this.id
      })
      .then((res) => {
        this.formData = res
        this.$set(this.formData, 'dateArr', [res.dateStart, res.dateEnd])
      })
      .finally(() => {
        this.loading = false
      })
  }

  changeDate (val: string[]) {
    if (val && val.length > 0) {
      this.formData.dateStart = val[0]
      this.formData.dateEnd = val[1]
    }
  }

  // 保存
  submit () {
    ;(this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        this.submitDisabled = true
        console.log(this.formData)
        delete this.formData.dateArr
        this.formData.deviceId = this.deviceId
        const urls = this.id ? this.$apis.devices.updateDeviceNetwork : this.$apis.devices.insertDeviceNetwork
        this.$axios.post(urls, this.formData).then(() => {
          this.$message.success('保存成功')

          this.$emit('saveSuccess')
        }).finally(() => {
          this.submitDisabled = false
        })
      }
    })
  }

  handleClose () {
    this.$emit('closeDialog')
  }
}
